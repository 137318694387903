import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Home from "./pages/home";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";

const Router = () => {

    return(
        <BrowserRouter>
            <Routes>
                <Route path="/home/:lang" element={<Home/>}/>
                <Route path="/terms/:lang" element={<TermsOfUse/>}/>
                <Route path="/privacy-policy/:lang" element={<PrivacyPolicy/>}/>
                <Route path="/cookie-policy/:lang" element={<CookiePolicy/>}/>
                <Route path="*" element={<Navigate to="/home/en" replace />} />
            </Routes>
      </BrowserRouter>
    )
}

export default Router;