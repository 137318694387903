import React from "react";
import { StyledLink, LinkWrapper } from "./styles";
import { useParams } from "react-router-dom";
import {
  MainHeadline,
  MainHeadlineWrapper,
  SubHeadlineWrapper,
  SubHeadline,
  MobileImage
} from "../style";
import fbImage from "../../assets/facebook.svg";
import xImage from "../../assets/x.svg";
import linkedinImage from "../../assets/linkedin.svg";

const LegalSection = ({ langObject = {} }) => {
  const { lang } = useParams();
  return (
    <>
      <MainHeadlineWrapper>
        <MainHeadline >{langObject.legalInformationHeadline}</MainHeadline>
      </MainHeadlineWrapper>
      <SubHeadlineWrapper >
        <SubHeadline >
          {langObject.legalInformationSubHeadline}
        </SubHeadline>
      </SubHeadlineWrapper>
      <LinkWrapper >
        <StyledLink  to={`/terms/${lang}`} margin={0}>{langObject.termsOfUseText}</StyledLink>
        <StyledLink  to={`/privacy-policy/${lang}`} margin={32}>{langObject.privacyPolicyText}</StyledLink>
        <StyledLink  to={`/cookie-policy/${lang}`} margin={32}>{langObject.cookiePolicy}</StyledLink>
      </LinkWrapper>
      <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:"60px", marginTop:"100px"}}>
        <MobileImage src={fbImage} style={{width:"30px"}} />
        <MobileImage src={xImage} style={{width:"30px"}} />
        <MobileImage src={linkedinImage} style={{width:"30px"}} />
      </div>
    </>
  );
};

export default LegalSection;
