import React from "react";
import PageContainer from "../../components/PageContainer";

import HomePageBanner from "../../components/HomePageBanner/HomePageBanner";
import CommitmentSection from "../../components/CommitmentSection";
import WhatsNextSection from "../../components/whatsNext";
import Carousal from "../../components/Carousal";
import InnovationsSection from "../../components/InnovationsSection";
import PioneersSection from "../../components/PioneersSection";
import ContactSection from "../../components/ContactSection";
import LegalSection from "../../components/LegalSection";
import DownloadAppSection from "../../components/DownloadAppSection";

import useGetLanguage from "../../hooks/useGetLanguage";

const Home = () => {
  const langObject = useGetLanguage("home");
  return (
    <PageContainer page={"home"}>
      <HomePageBanner langObject={langObject} />
      <CommitmentSection langObject={langObject} />
      {/* <WhatsNextSection langObject={langObject} /> */}
      {/* <Carousal /> */}
      <PioneersSection langObject={langObject} />
      <DownloadAppSection langObject={langObject} />
      <InnovationsSection langObject={langObject} />
      <ContactSection langObject={langObject} />
      <LegalSection langObject={langObject} />
    </PageContainer>
  );
};

export default Home;
